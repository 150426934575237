.document-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 50px;
}

.document-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.document-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.document-list-item:hover .document-link {
  background-color: #f0f0f0;
}

.document-name {
  display: flex;
  align-items: center;
  flex: 1;
}

.document-actions {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.document-name svg, .document-actions svg {
  margin-right: 5px;
}